<template>
  <div id="glitter-profile" v-if="$store.getters.getLoginStatus">
    <h4>{{ $t('profileView_headline') }}</h4>

    <div class="glitter-profile-group">
      <div v-for="entry in profileData.schema" class="glitter-profile-row" :key="entry.weight">
        <template v-if="entry.field !== 'tasks'">
          <label class="glitter-profile-label">
            {{ $t('label_' + entry.field) }}
          </label>

          <span v-if="entry.type !== 'select' && entry.type !== 'date'" class="glitter-profile-value">
            {{ profileData.profile[entry.field] }}
          </span>

          <span v-else-if="entry.type === 'date'" class="glitter-profile-value">
            {{ germanDate(profileData.profile[entry.field]) }}
          </span>

          <span v-else class="glitter-profile-value">
            {{ $t('label_' + profileData.profile[entry.field]) }}
          </span>
        </template>
      </div>
    </div>
    <div class="glitter-profile-group-tasks" v-if="profileData.profile.tasks && infoTasks">
      <template v-for="(item, key) in infoTasks">
        <div class="glitter-profile-row" v-if="item.visibility && item.visibility.web && item.visibility.web.profile && profileData.profile.tasks[key]" :key="key">
          <label v-html="item.label" class="glitter-profile-label"></label>
        </div>
      </template>
    </div>

  <div class="glitter-profile-footer">
    <a class="glitterLogout" :class="{ 'uk-button uk-button-danger': uikit }" @click.prevent="logOut" :title="$t('buttonLogout')" href="#">{{ $t('buttonLogout') }}</a>
    <a class="glitterProfileEdit" :class="{ 'uk-button uk-button-default': uikit }" :title="$t('buttonProfileEdit')" target="_self" href="#" @click.prevent="editProfile()">{{ $t('buttonProfileEdit') }}</a>
  </div>

  </div>
</template>

<script>
import StorageService from '@/services/storage'
import ProcessService from '@/services/process'
import UserService from '@/services/user'

export default {
  name: 'profile',
  data () {
    return {
      profileData: StorageService.getProfile(),
      infoTasks: null
    }
  },
  beforeMount () {
    this.getInfoTasks()
  },
  methods: {
    logOut () {
      ProcessService.logoutProcess()
    },
    editProfile () {
      this.$store.commit('changeView', 'profile-edit')
    },
    germanDate (date) {
      if (date) {
        return date.split('-').reverse().join('.')
      }
    },
    async getInfoTasks () {
      try {
        const infoTasks = await UserService.infoTasks()
        this.infoTasks = infoTasks
      } catch (e) {
        // console.error(e)
      }
    }
  }
}
</script>
