<template>
  <div id="glitter-login">
    <a class="registrationLink" :class="{ 'uk-button uk-button-default': uikit }" :title="$t('buttonRegistration')" target="_self" href="#" @click.prevent="showRegistration()">{{ $t('buttonRegistration') }}</a>
    <p class="headlineText">{{ $t('loginViewText_or') }}</p>
    <h4 class="loginHeadline">{{ $t('loginView_headline') }}</h4>
    <form ref="loginForm" @submit.prevent="loginSubmit()">
      <div class="glitter-form-row">
        <label for="glitter-email">{{ $t('label_email') }}</label>
        <input type="email" required name="email" v-model="formData.email" id="glitter-email" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row">
        <label for="glitter-password">{{ $t('label_password') }}</label>
        <input type="password" required name="password" v-model="formData.password" id="glitter-password" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row buttonRow">
        <a v-if="facebookEnabled" class="glitterFacebookLogin" :class="{ 'uk-button uk-button-default': uikit }" :title="$t('buttonFacebook')" target="_self" href="#" @click.prevent="loginWithFacebook()">{{ $t('buttonFacebook') }}</a>
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonLogin') }}</button>
      </div>
      <template v-if="infoTasks">
        <template v-for="(item, key) in infoTasks">
          <template v-if="item.visibility && item.visibility.web && item.visibility.web.login">
            <div class="glitter-form-row" v-html="item.label" :key="key"></div>
          </template>
        </template>
      </template>
      <p>
        <a class="glitterReset" :class="{ 'uk-button uk-button-text': uikit }" :title="$t('buttonReset')" target="_self" href="#" @click.prevent="resetPassword()">{{ $t('buttonReset') }}</a>
      </p>
    </form>
  </div>
</template>

<script>
import ProcessService from '@/services/process'
import UserService from '@/services/user'

export default {
  name: 'login',
  data () {
    return {
      infoTasks: null,
      formData: {}
    }
  },
  beforeMount () {
    this.getInfoTasks()
  },
  methods: {
    loginSubmit () {
      ProcessService.loginProcess(this.formData)
    },
    loginWithFacebook () {
      ProcessService.fbTokenPopup()
    },
    resetPassword () {
      this.$store.commit('changeView', 'reset')
    },
    showRegistration () {
      this.$store.commit('changeView', 'registration')
    },
    async getInfoTasks () {
      try {
        const infoTasks = await UserService.infoTasks()
        this.infoTasks = infoTasks
      } catch (e) {
        // console.error(e)
      }
    }
  },
  computed: {
    facebookEnabled () {
      return !!this.$store.getters.getFacebookID
    }
  }
}
</script>
