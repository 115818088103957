const translations = {

  // loader
  loading: 'loading',

  // common buttons
  buttonLogout: 'Logout',
  buttonBack: 'zurück',
  buttonFacebook: 'Facebook',

  // labels
  label_: '',
  label_email: 'E-Mail',
  label_code: 'Code',
  label_password: 'Passwort',
  label_new_password: 'Neues Passwort',
  label_gender: 'Anrede',
  label_first_name: 'Vorname',
  label_last_name: 'Nachname',
  label_birthday: 'Geburtstag',
  label_phone: 'Telefon',
  label_address: 'Adresse',
  label_zip: 'PLZ',
  label_city: 'Ort',
  label_country: 'Land',
  label_male: 'Herr',
  label_female: 'Frau',
  label_other: 'Andere',

  // login view
  loginView_headline: 'Login',
  buttonLogin: 'Einloggen',
  loginViewText_or: 'oder',

  // registration view
  registrationView_headline: 'Registrierung',
  buttonRegistration: 'Registrieren',

  // registration view
  createAccountView_headline: 'Registrierung vervollständigen',
  createAccountView_text: 'Vielen Dank für die Nachricht. Die Daten aus dem Formular werden automatisch in das Profil übernommen.',
  buttonCreateAccount: 'Registrieren',

  // reset view
  resetView_headline: 'Passwort zurücksetzen',
  buttonReset: 'Passwort zurücksetzen',

  // profile view
  profileView_headline: 'Profil',
  buttonProfileEdit: 'Profil bearbeiten',

  // profile edit view
  profileEditView_headline: 'Profil bearbeiten',
  buttonProfileEditSave: 'Profil speichern',
  deleteAccount_link: 'Profil löschen',
  deleteAccount_confirmText: 'Soll dieses Benutzerkonto wirklich unwiderruflich gelöscht werden?',

  // confirm account view
  confirmAccountView_headline: 'Konto bestätigen',
  buttonConfirmAccount: 'Bestätigen',
  buttonResendCode: 'Code erneut senden',

  // confirm email view
  confirmEmailView_headline: 'Email bestätigen',
  buttonConfirmEmail: 'Bestätigen',

  // refresh view
  refreshView_headline: 'Passwort aktualisieren',
  buttonRefresh: 'Passwort aktualisieren',

  // tasks view
  tasksView_headline: 'Benötigte Informationen',
  buttonTasks: 'Speichern',

  // Errors
  "Cannot read properties of undefined (reading 'status')": 'Login temporär nicht verfügbar - Bitte zu einem späteren Zeitpunkt erneut versuchen'

}

export default translations
