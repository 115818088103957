<template>
  <div id="glitter-reset">
    <h4>{{ $t('resetView_headline') }}</h4>

    <form ref="resetForm" @submit.prevent="resetSubmit()">
      <div class="glitter-form-row">
        <label for="glitter-email">{{ $t('label_email') }}</label>
        <input type="email" required name="email" v-model="formData.email" id="glitter-email" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row buttonRow">
        <a class="glitterBack" :title="$t('buttonBack')" target="_self" href="#" @click.prevent="backToLogin()" :class="{ 'uk-button uk-button-text': uikit }">{{ $t('buttonBack') }}</a>
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonReset') }}</button>
      </div>
    </form>

  </div>
</template>

<script>
import ProcessService from '@/services/process'

export default {
  name: 'reset',
  data () {
    return {
      formData: {}
    }
  },
  methods: {
    resetSubmit () {
      ProcessService.resetProcess(this.formData)
    },
    backToLogin () {
      this.$store.commit('changeView', 'login')
    }
  }
}
</script>
