<template>
  <div id="glitter-tasks">
    <h4>{{ $t('tasksView_headline') }}</h4>

    <form ref="tasksForm" @submit.prevent="tasksSubmit()">

      <div class="glitter-form-row" v-for="task in tasks.fields" :key="task.field">

        <template v-if="task.type === 'text' || task.type === 'email' || task.type === 'tel'">
          <label :for="'glitter-' + task.field">{{ $t('label_' + task.field) }}</label>
          <input :type="task.type" required :name="task.field" v-model="formData[task.field]" :id="'glitter-' + task.field" :class="{ 'uk-input': uikit }" />
        </template>

        <template v-if="task.type === 'date'">
          <label :for="'glitter-' + task.field">{{ $t('label_' + task.field) }}</label>
          <input :type="task.type" required :name="task.field" v-model="formData[task.field]" :id="'glitter-' + task.field" max="2999-12-31" :class="{ 'uk-input': uikit }" />
        </template>

        <template v-if="task.type === 'checkbox' || task.type === 'radio'">
          <label>
            <input :type="task.type" required :name="task.field" v-model="formData[task.field]" :id="'glitter-' + task.field" :class="{ 'uk-checkbox': uikit }" />
            {{ $t('label_' + task.field) }}
          </label>
        </template>

        <template v-if="task.type === 'select'">
          <label :for="'glitter-' + task.field">{{ $t('label_' + task.field) }}</label>
          <select :name="task.field" required :id="'glitter-' + task.field" v-model="formData[task.field]" :class="{ 'uk-select': uikit }">
            <option v-for="option in task.inclusion" :value="option.key" :key="option.key">{{ $t('label_' + option.key) }}</option>
          </select>
        </template>

      </div>

      <div class="glitter-form-row" v-for="otherTask in tasks.other" :key="otherTask.label">

        <template v-if="otherTask.type === 'text' || otherTask.type === 'email' || otherTask.type === 'radio' || otherTask.type === 'date'">
          <label>{{ $t('label_' + otherTask.label) }}</label>
          <input :type="otherTask.type" :name="'tasks[' + otherTask.requirement + ']'" v-model="formData.tasks[otherTask.requirement]" :class="{ 'uk-input': uikit }" />
        </template>

        <template v-if="otherTask.type === 'checkbox' || otherTask.type === 'radio'">
          <label>
            <input :type="otherTask.type" :name="'tasks[' + otherTask.requirement + ']'" v-model="formData.tasks[otherTask.requirement]" :class="{ 'uk-checkbox': uikit }" />
            <span v-html="otherTask.label"></span>
          </label>
        </template>

      </div>

      <div class="glitter-form-row buttonRow">
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonTasks') }}</button>
      </div>

    </form>

  </div>
</template>

<script>
import ProcessService from '@/services/process'
import StorageService from '@/services/storage'

export default {
  name: 'tasks',
  data () {
    return {
      tasks: StorageService.getTasks(),
      formData: {
        tasks: {}
      }
    }
  },
  methods: {
    tasksSubmit () {
      // change birthday format
      if (this.formData.birthday) {
        const initFormat = this.formData.birthday
        const finalFormat = initFormat.split('-').reverse().join('.')
        this.formData.birthday = finalFormat
      }

      // Add empty optional task checkboxes state to form data.
      if (this.tasks.other && Array.isArray(this.tasks.other) && this.tasks.other.length) {
        this.tasks.other.forEach((task) => {
          if (task.optional && !this.formData.tasks[task.requirement]) this.formData.tasks[task.requirement] = false
        })
      }

      ProcessService.tasksProcess(this.formData)
    }
  },
  mounted () {
    // subscribe to the store to update tasks data
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'changeView' && mutation.payload === 'tasks') {
        this.tasks = StorageService.getTasks()
      }
    })
  }
}
</script>
