const TOKEN_KEY = 'glitter.access_token'
const TASKS_EXPIRES_KEY = 'glitter.token_expires'
const REFRESH_TOKEN_KEY = 'glitter.refresh_token'
const PROFILE_KEY = 'glitter.user_profile'
const TASKS_KEY = 'glitter.tasks'

/**
 * Manage tokens and profile data
**/
const StorageService = {
  getToken () {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken (accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken () {
    localStorage.removeItem(TOKEN_KEY)
  },

  getRefreshToken () {
    return localStorage.getItem(REFRESH_TOKEN_KEY)
  },

  saveRefreshToken (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken () {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  getTokenExpires () {
    return localStorage.getItem(TASKS_EXPIRES_KEY)
  },

  saveTokenExpires (timestamp) {
    localStorage.setItem(TASKS_EXPIRES_KEY, timestamp)
  },

  removeTokenExpires () {
    localStorage.removeItem(TASKS_EXPIRES_KEY)
  },

  getProfile () {
    return JSON.parse(localStorage.getItem(PROFILE_KEY))
  },

  saveProfile (profileData) {
    if (Object.keys(profileData).length) {
      localStorage.setItem(PROFILE_KEY, JSON.stringify(profileData))
      if (window && 'postMessage' in window) window.postMessage('profile:update')
    }
  },

  removeProfile () {
    localStorage.removeItem(PROFILE_KEY)
  },

  getTasks () {
    return JSON.parse(localStorage.getItem(TASKS_KEY))
  },

  saveTasks (tasksData) {
    if (Object.keys(tasksData).length) {
      localStorage.setItem(TASKS_KEY, JSON.stringify(tasksData))
    }
  },

  removeTasks () {
    localStorage.removeItem(TASKS_KEY)
  }

}

export default StorageService
