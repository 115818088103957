import axios from 'axios'
import store from '../store'
import ApiService from './api'
import StorageService from './storage'
import UserService from './user'

// unset errors before every new request
axios.interceptors.request.use((config) => {
  store.commit('removeAuthenticationError')
  store.commit('removeSuccessMsg')
  return config
})

const ProcessService = {
  tempEmail: null,

  /**
   * Check in process
   **/
  checkInProcess () {
    // token available?
    if (StorageService.getToken()) {
      const expires = StorageService.getTokenExpires()
      const epDate = new Date(expires * 1000) < new Date()
      const isExpired = expires ? epDate : false

      // if the token is not expired
      if (!isExpired) {
        ApiService.setHeader()
        UserService.checkIn()
          .then(({ tasks }) => {
            const view = Object.keys(tasks).length ? 'tasks' : 'profile'
            store.commit('changeView', view)
            store.commit('loginStatus', true)
          })
          .catch(error => store.commit('setAuthenticationError', error))
      } else {
        // try width refresh token (if available)
        if (StorageService.getRefreshToken()) {
          ProcessService.refreshTokenProcess()
        }
      }
    }
  },

  /**
   * login process
   **/
  loginProcess (loginData) {
    UserService.login(loginData)
      .then(({ tasks }) => {
        const view = Object.keys(tasks).length ? 'tasks' : 'profile'
        store.commit('changeView', view)
        store.commit('loginStatus', true)
      })
      .catch(error => {
        // account not confirmed
        if (error.errorCode === 403) {
          store.commit('changeView', 'confirm-account')
          ProcessService.tempEmail = loginData.email
        } else {
          store.commit('setAuthenticationError', error)
        }
      })
  },

  /**
   * refresh token process
   **/
  async refreshTokenProcess () {
    await UserService.refreshToken()
      .then(({ tokens }) => {
        StorageService.saveToken(tokens.id)
        StorageService.saveTokenExpires(tokens.expires)
        ApiService.setHeader()
        store.commit('changeView', 'profile')
        store.commit('loginStatus', true)
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
   * registration process
   **/
  registrationProcess (regData) {
    UserService.registration(regData)
      .then(({ message }) => {
        store.commit('changeView', 'confirm-account')
        store.commit('setSuccessMsg', message)
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
   * confirm account process
   **/
  confirmAccountProcess (confirmData) {
    UserService.confirmAccount(confirmData)
      .then(({ message }) => {
        store.commit('changeView', 'login')
        store.commit('setSuccessMsg', message)
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
   * confirm email process
   **/
  confirmEmailProcess (confirmData) {
    UserService.confirmEmail(confirmData)
      .then(({ message }) => {
        ApiService.get('/profile?schema')
          .then(response => {
            const { profile, schema } = response.data
            StorageService.saveProfile({ profile, schema })
          })
          .then(() => store.commit('changeView', 'profile'))
          .then(() => store.commit('setSuccessMsg', message))
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
  * reset process
  **/
  resetProcess (resetData) {
    UserService.reset(resetData)
      .then(({ message }) => {
        store.commit('changeView', 'refresh')
        store.commit('setSuccessMsg', message)
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
  * refresh process
  **/
  refreshProcess (refreshData) {
    UserService.refresh(refreshData)
      .then(({ message }) => {
        store.commit('changeView', 'login')
        store.commit('setSuccessMsg', message)
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
  * profile edit process
  **/
  profileEditProcess (profileData) {
    const formMail = profileData.email
    const { profile } = StorageService.getProfile()
    const storageMail = profile.email

    // check if email changed
    if (formMail && storageMail !== formMail) {
      // update email and update profile
      UserService.profileEdit(profileData)
        .then(() => UserService.profileEmailUpdate(formMail))
        .then(() => UserService.profileEdit(profileData))
        .then(() => store.commit('changeView', 'confirm-email'))
        .catch(profileEditError)
    } else {
      // only update profile
      UserService.profileEdit(profileData)
        .then(() => store.commit('changeView', 'profile'))
        .catch(profileEditError)
    }

    // profile-edit error / refresh
    function profileEditError (error) {
      // user session expired
      if (error.errorCode === 401) {
        if (StorageService.getRefreshToken()) {
          ProcessService.refreshTokenProcess().then(() => ProcessService.profileEditProcess(profileData))
        } else {
          store.commit('changeView', 'login')
          store.commit('setAuthenticationError', { errorCode: 'custom', message: 'Die Sitzung ist abgelaufen' })
        }
      } else {
        store.commit('setAuthenticationError', error)
      }
    }
  },

  /**
  * tasks process
  **/
  tasksProcess (tasksData) {
    const formMail = tasksData.email

    UserService.tasks(tasksData)
      .then(({ tasks, profile, schema }) => {
        StorageService.removeTasks()

        if (formMail) {
          UserService.profileEmailUpdate(formMail)
            .then(() => store.commit('changeView', 'confirm-email'))
            .catch(error => store.commit('setAuthenticationError', error))
        } else {
          if (Object.keys(tasks).length) {
            StorageService.saveTasks(tasks)
            store.commit('changeView', 'tasks')
          } else {
            StorageService.saveProfile({ profile, schema })
            store.commit('changeView', 'profile')
          }
        }
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
  * resend confirm code
  **/
  resendConfirmCode (email) {
    UserService.resendCode(email)
      .then(() => {
        ProcessService.tempEmail = null
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
   * Open facebook popup to get an access token
   **/
  fbTokenPopup () {
    // build params
    const searchParams = new URLSearchParams()
    searchParams.append('client_id', store.getters.getFacebookID)
    searchParams.append('redirect_uri', document.location.origin)
    searchParams.append('response_type', 'token')
    searchParams.append('scope', 'email')
    searchParams.append('display', 'popup')

    // fb popup
    const popupOptions = 'width=500,height=400,toolbar=no,scrollbars=no,resizable=no,menubar=no,titlebar=no'
    window.open(`https://www.facebook.com/dialog/oauth?${searchParams}`, 'Facebook login', popupOptions)
  },

  /**
   * Get facebook token from URL, get long-lived-token, save the response and close the popup
   **/
  fbTokenProcess () {
    const fbParams = new URLSearchParams(document.location.hash.substr(1))
    const accessToken = fbParams.get('access_token')

    // save facebook token and close popup
    if (accessToken) {
      ApiService.post('/auth/exchange_token', { token: accessToken })
        .then(({ data }) => {
          UserService.saveFbUserData({
            accessToken: data.token,
            expires: data.expires
          })
        })
        .catch(error => store.commit('setAuthenticationError', error))
        .finally(() => window.close())
    }
  },

  /**
   * Init oauth login process and redirect
   **/
  oauthProcess (loginData) {
    const urlParams = new URLSearchParams(window.location.search)
    UserService.oauthLogin(loginData, urlParams)
      .then(({ uri, state, code }) => {
        window.location = uri + '?state=' + state + '&code=' + code
      })
      .catch(error => store.commit('setAuthenticationError', error))
  },

  /**
   * delete account process
   **/
  deleteProcess () {
    UserService.deleteAccount()
      .then(() => this.logoutProcess())
  },

  /**
   * logout process
   **/
  logoutProcess () {
    UserService.logout()
    store.commit('loginStatus', false)
    store.commit('changeView', 'login')
  }

}

export default ProcessService
