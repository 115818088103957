<template>
  <div id="glitter-login">
    <h4>{{ $t('loginView_headline') }}</h4>

    <form ref="loginForm" @submit.prevent="loginSubmit()">
      <div class="glitter-form-row">
        <label for="glitter-email">{{ $t('label_email') }}</label>
        <input type="email" required name="email" v-model="formData.email" id="glitter-email" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row">
        <label for="glitter-password">{{ $t('label_password') }}</label>
        <input type="password" required name="password" v-model="formData.password" id="glitter-password" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row buttonRow">
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonLogin') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import ProcessService from '@/services/process'

export default {
  name: 'login',
  data () {
    return {
      formData: {}
    }
  },
  methods: {
    loginSubmit () {
      ProcessService.oauthProcess(this.formData)
    }
  }
}
</script>
