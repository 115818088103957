export default {
  changeView (state, payload) {
    state.view = payload
  },
  loadingStatus (state, payload) {
    state.loading = payload
  },
  loginStatus (state, payload) {
    state.loggedIn = payload
  },
  setAuthenticationError (state, { errorCode, message }) {
    state.authenticationErrorMsg = message
    state.authenticationErrorCode = errorCode
  },
  removeAuthenticationError (state) {
    state.authenticationErrorMsg = false
    state.authenticationErrorCode = false
  },
  setSuccessMsg (state, message) {
    state.successMsg = message
  },
  removeSuccessMsg (state) {
    state.successMsg = false
  },
  setFacebookID (state, payload) {
    state.facebookID = payload
  }
}
