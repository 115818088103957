<template>
  <div id="glitter-profile-edit" v-if="$store.getters.getLoginStatus">
    <h4>{{ $t('profileEditView_headline') }}</h4>

    <form ref="profileEditForm" @submit.prevent="profileSubmit()">

      <div class="profileTab" v-if="profileData.profile">
        <div class="glitter-form-row" v-for="entry in profileSchema" :key="entry.weight">
          <template v-if="entry.field !== 'tasks'">

            <template v-if="entry.type !== 'select' && entry.type !== 'date'">
              <label :for="'glitter-' + entry.field" :class="'required-' + entry.required">{{ $t('label_' + entry.field) }}</label>
              <input :type="entry.type" :required="entry.required" :name="entry.field" v-model="profileData.profile[entry.field]" :id="'glitter-' + entry.field" :pattern="getValidationPattern(entry.field)" :class="{ 'uk-input': uikit }" />
            </template>

            <template v-else-if="entry.type === 'date'">
              <label :for="'glitter-' + entry.field" :class="'required-' + entry.required">{{ $t('label_' + entry.field) }}</label>
              <input :type="entry.type" :required="entry.required" :name="entry.field" v-model="profileData.profile[entry.field]" :id="'glitter-' + entry.field" :pattern="getValidationPattern(entry.field)" max="2999-12-31" :class="{ 'uk-input': uikit }" />
            </template>

            <template v-else>
              <label :for="'glitter-' + entry.field" :class="'required-' + entry.required">{{ $t('label_' + entry.field) }}</label>
              <select :name="entry.field" :id="'glitter-' + entry.field" v-model="profileData.profile[entry.field]" :required="entry.required" :class="{ 'uk-select': uikit }">
                <option v-for="option in entry.inclusion" :value="option.key" :key="option.key">{{ entry.rawLabel ? option.label : $t('label_' + option.key) }}</option>
              </select>
            </template>

          </template>
        </div>
      </div>

      <div class="tasksTab" v-if="profileData.profile.tasks">
        <template v-if="infoTasks">
          <template v-for="(item, key) in infoTasks">
            <template v-if="item.visibility && item.visibility.web && item.visibility.web.profile">
              <div class="glitter-form-row" :key="key">
                <label v-if="item.type === 'checkbox' || item.type === 'radio'">
                  <input :type="item.type" :name="'tasks['+key+']'" v-model="profileData.profile.tasks[key]" :required="!item.optional" :class="{ 'uk-checkbox': uikit }" />
                  <span v-html="item.label"></span>
                </label>
              </div>
            </template>
          </template>
        </template>
      </div>

      <div class="deleteAccount">
        <p><a :title="$t('deleteAccount_link')" href="#" @click.prevent="deleteAccount()" :class="{ 'uk-button uk-button-text': uikit }">{{ $t('deleteAccount_link') }}</a></p>
      </div>

      <div class="glitter-form-row buttonRow">
        <a class="glitterBack" :title="$t('buttonBack')" target="_self" href="#" @click.prevent="backToProfile()" :class="{ 'uk-button uk-button-text': uikit }">{{ $t('buttonBack') }}</a>
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonProfileEditSave') }}</button>
      </div>

    </form>
  </div>
</template>

<script>
import StorageService from '@/services/storage'
import ProcessService from '@/services/process'
import UserService from '@/services/user'
import countries from '../countries'

export default {
  name: 'profile-edit',
  data () {
    return {
      profileData: StorageService.getProfile(),
      infoTasks: null
    }
  },
  computed: {
    profileSchema () {
      return this.profileData.schema.map((entry) => {
        if (entry.field === 'country') {
          entry.type = 'select'
          entry.inclusion = ['', ...countries.map((country) => {
            return { key: country, label: country }
          })]
          entry.rawLabel = true
          return entry
        } else {
          return entry
        }
      })
    }
  },
  beforeMount () {
    this.getInfoTasks()

    // convert birthday value
    if (this.profileData && this.profileData.profile && this.profileData.profile.birthday) {
      this.profileData.profile.birthday = this.setBirthday(this.profileData.profile.birthday)
    }
  },
  methods: {
    profileSubmit () {
      const formData = Object.assign({}, this.profileData.profile)

      // change birthday format
      if (formData.birthday) {
        const initFormat = formData.birthday

        if (initFormat.indexOf('-') > -1) {
          const finalFormat = initFormat.split('-').reverse().join('.')
          formData.birthday = finalFormat
        }
      }

      // Account for possibly empty country field
      if (typeof formData.country === 'undefined') {
        formData.country = ''
      }

      ProcessService.profileEditProcess(formData)
    },
    backToProfile () {
      this.$store.commit('changeView', 'profile')
    },
    setBirthday (date) {
      if (date) {
        return date.split('.').reverse().join('-')
      }
    },
    getValidationPattern (type) {
      if (type === 'first_name' || type === 'last_name' || type === 'city') {
        return '[A-Za-zßÜÖÄüöä -]{1,}'
      } else if (type === 'birthday') {
        return '{4}-{2}-{2}'
      }
    },
    deleteAccount () {
      const deleteRequest = confirm(this.$t('deleteAccount_confirmText'))

      if (deleteRequest) ProcessService.deleteProcess()
    },
    async getInfoTasks () {
      try {
        const infoTasks = await UserService.infoTasks()
        this.infoTasks = infoTasks
      } catch (e) {
        // console.error(e)
      }
    }
  }
}
</script>
