import Vue from 'vue'
import App from '@/App'
import store from '@/store'
import VueI18n from 'vue-i18n'
import ApiService from '@/services/api'
import ProcessService from '@/services/process'
import 'url-search-params-polyfill'

// deactivate tips
Vue.config.productionTip = false

// get login mount point
const loginMount = document.querySelector('#glitterLogin')

// mount point is present
if (loginMount) {
  // get login config
  const appData = loginMount.dataset

  // set facebook id
  if (appData.facebookId) {
    store.commit('setFacebookID', appData.facebookId)
  }

  // set initial view
  if (appData.initialView) {
    store.commit('changeView', appData.initialView)
  }

  // use uikit classes
  Vue.prototype.uikit = 'uikit' in appData

  // Init ApiService with API URL
  if (appData.apiUrl) {
    ApiService.init(appData.apiUrl + '/api')

    // check in process
    if (window.localStorage) ProcessService.checkInProcess()

    // use i18n (internationalization plugin)
    Vue.use(VueI18n)

    // i18n config
    const i18n = new VueI18n({
      locale: 'de-DE',
      messages: {
        'de-DE': require('./translations.js').default
      }
    })

    // init vue with modules
    new Vue({
      i18n,
      store,
      render: h => h(App)
    }).$mount('#glitterLogin')

    // add some methods to window
    window.glitterApp = {
      loginState: store.getters.getLoginStatus,
      showView: (viewType = 'login') => store.commit('changeView', viewType),
      logout: () => ProcessService.logoutProcess(),
      profileUpdate: (profile) => ProcessService.profileEditProcess(profile),
      loginStateEvent: new Vue()
    }

    // vuex state changes
    store.subscribe((mutation, state) => {
      if (mutation.type === 'loginStatus') {
        window.glitterApp.loginState = mutation.payload
        window.glitterApp.loginStateEvent.$emit('loginState', mutation.payload)
      }
    })
  } else {
    /* eslint no-console: 0 */
    console.warn('No API URL provided: %c<div id="glitterLogin" data-api-url="[API_URL]"></div>', 'color: orange; font-style: italic;')
  }
} else {
  /* eslint no-console: 0 */
  console.warn('Missing mount point: %c#glitterLogin', 'color: orange; font-style: italic;')
}
