import axios from 'axios'
import StorageService from './storage'

const ApiService = {

  init (baseURL) {
    axios.defaults.baseURL = baseURL
  },

  setHeader () {
    axios.defaults.headers.common['Authorization'] = `Bearer ${StorageService.getToken()}`
  },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  get (resource) {
    return axios.get(resource)
  },

  post (resource, data) {
    return axios.post(resource, data)
  },

  delete (resource) {
    return axios.delete(resource)
  }

}

export default ApiService
