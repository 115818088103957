<template>
  <div id="glitterApp">
    <div id="glitterLoader" v-if="getLoadingStatus">{{ $t('loading') }}</div>

    <div id="successMessage" v-if="getSuccessMsg">
      {{ getSuccessMsg }}
    </div>

    <div id="errorMessage" v-if="getAuthenticationErrorMsg" :class="'errorMessage error' + getAuthenticationErrorCode">
      {{ $t(getAuthenticationErrorMsg) }}
    </div>

    <component :is="getView" />
  </div>
</template>

<script>
import ProcessService from '@/services/process'
import Login from '@/components/login'
import LoginOauth from '@/components/login-oauth'
import Registration from '@/components/registration'
import CreateAccount from '@/components/create-account'
import ConfirmEmail from '@/components/confirm-email'
import ConfirmAccount from '@/components/confirm-account'
import Profile from '@/components/profile'
import ProfileEdit from '@/components/profile-edit'
import Reset from '@/components/reset'
import Refresh from '@/components/refresh'
import Tasks from '@/components/tasks'
import { mapGetters } from 'vuex'

export default {
  name: 'glitter',
  components: {
    Login,
    LoginOauth,
    Registration,
    CreateAccount,
    ConfirmEmail,
    ConfirmAccount,
    Profile,
    ProfileEdit,
    Reset,
    Refresh,
    Tasks
  },
  mounted () {
    // get fb token from url (popup)
    if (document.location.hash.indexOf('access_token=') > -1) {
      ProcessService.fbTokenProcess()
    }

    // add storage listener to react on fb access token updates (on main window)
    window.addEventListener('storage', this.onStorageUpdate, false)
  },
  computed: {
    ...mapGetters([
      'getView',
      'getLoadingStatus',
      'getAuthenticationErrorMsg',
      'getAuthenticationErrorCode',
      'getSuccessMsg'
    ])
  },
  methods: {
    onStorageUpdate (event) {
      // try to check in if the access token changed
      if (event.key === 'glitter.access_token' && event.newValue !== event.oldValue) {
        ProcessService.checkInProcess()
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('storage', this.onStorageUpdate)
  }
}
</script>
