<template>
  <div id="glitter-refresh">
    <h4>{{ $t('refreshView_headline') }}</h4>

    <form ref="refreshForm" @submit.prevent="refreshSubmit()">
      <div class="glitter-form-row">
        <label for="glitter-email">{{ $t('label_email') }}</label>
        <input type="email" required name="email" v-model="formData.email" id="glitter-email" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row">
        <label for="glitter-code">{{ $t('label_code') }}</label>
        <input type="text" required name="code" v-model="formData.code" id="glitter-code" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row">
        <label for="glitter-password">{{ $t('label_new_password') }}</label>
        <input type="password" required name="password" v-model="formData.password" id="glitter-password" :class="{ 'uk-input': uikit }" />
      </div>
      <div class="glitter-form-row buttonRow">
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonRefresh') }}</button>
      </div>
    </form>

  </div>
</template>

<script>
import ProcessService from '@/services/process'

export default {
  name: 'refresh',
  data () {
    return {
      formData: {}
    }
  },
  methods: {
    refreshSubmit () {
      ProcessService.refreshProcess(this.formData)
    }
  }
}
</script>
