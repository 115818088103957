<template>
  <div id="glitter-createAccount">
    <h4>{{ $t('createAccountView_headline') }}</h4>

    <p v-if="$t('createAccountView_text')" class="introText">{{ $t('createAccountView_text') }}</p>

    <form ref="regForm" @submit.prevent="regSubmit()">
      <div class="glitter-form-row">
        <label for="glitter-password">{{ $t('label_password') }}</label>
        <input type="password" required name="password" v-model="formData.password" id="glitter-password" :class="{ 'uk-input': uikit }" />
      </div>
      <template v-if="infoTasks">
        <template v-for="(item, key) in infoTasks">
          <template v-if="item.visibility && item.visibility.web && item.visibility.web.registration">
            <div class="glitter-form-row" :key="key">
              <label v-if="item.type === 'checkbox' || item.type === 'radio'">
                <input :type="item.type" :required="!item.optional" :name="'tasks['+key+']'" v-model="formData.tasks[key]" :class="{ 'uk-checkbox': uikit }" />
                <span v-html="item.label"></span>
              </label>
            </div>
          </template>
        </template>
      </template>
      <div class="glitter-form-row buttonRow">
        <a class="glitterBack" :title="$t('buttonBack')" target="_self" href="#" @click.prevent="backToLogin()" :class="{ 'uk-button uk-button-text': uikit }">{{ $t('buttonBack') }}</a>
        <button type="submit" :class="{ 'uk-button uk-button-default': uikit }">{{ $t('buttonCreateAccount') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import ProcessService from '@/services/process'
import UserService from '@/services/user'

export default {
  name: 'create-account',
  data () {
    return {
      infoTasks: null,
      formData: {
        tasks: {}
      }
    }
  },
  beforeMount () {
    this.getInfoTasks()
  },
  mounted () {
    window.addEventListener('message', this.receiveAccountData, false)
  },
  methods: {
    regSubmit () {
      ProcessService.registrationProcess(this.formData)
    },
    backToLogin () {
      this.$store.commit('changeView', 'login')
    },
    async getInfoTasks () {
      try {
        const infoTasks = await UserService.infoTasks()
        this.infoTasks = infoTasks
      } catch (e) {
        // console.error(e)
      }
    },
    receiveAccountData (event) {
      if (event.origin === document.location.origin) {
        const { data } = event

        if (data.bday) this.formData.birthday = data.bday
        if (data.city) this.formData.city = data.city
        if (data.country) this.formData.country = data.country
        if (data.email) this.formData.email = data.email
        if (data.fname) this.formData.first_name = data.fname
        if (data.lname) this.formData.last_name = data.lname
        if (data.gender) this.formData.gender = data.gender
        if (data.phone) this.formData.phone = data.phone
        if (data.street) this.formData.address = data.street
        if (data.zip) this.formData.zip = data.zip
      }
    }
  }
}
</script>
