import Vue from 'vue'
import Vuex from 'vuex'
import state from './_state'
import mutations from './_mutations'
import getters from './_getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters
})

export default store

export {
  state,
  mutations,
  getters
}
