import store from '../store'
import ApiService from './api'
import StorageService from './storage'

class AuthenticationError extends Error {
  constructor (errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  /**
   * Check in
   **/
  checkIn: async function () {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/checkin?profile&schema')
      this.saveUserData(response)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Login
   **/
  login: async function (loginData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/login?profile&schema', loginData)
      this.saveUserData(response)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Registration
   **/
  registration: async function (regData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/register', regData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Edit profile data
   **/
  profileEdit: async function (profileData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/profile?schema', profileData)
      StorageService.saveProfile({ profile: response.data.profile, schema: response.data.schema })
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Update email address
   **/
  profileEmailUpdate: async function (mailAddress) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/email/update', { email: mailAddress })
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Confirm account
   **/
  confirmAccount: async function (confirmData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/register/confirm', confirmData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Confirm email
   **/
  confirmEmail: async function (confirmData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/email/confirm', confirmData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Refresh the access token
   **/
  refreshToken: async function () {
    store.commit('loadingStatus', true)
    const refreshToken = StorageService.getRefreshToken()

    try {
      const response = await ApiService.post('/auth/refresh', { refresh: refreshToken })
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Reset password
   **/
  reset: async function (resetData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/password/reset', resetData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Reset password with code
   **/
  refresh: async function (refreshData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/auth/password/refresh', refreshData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Save tasks
   **/
  tasks: async function (tasksData) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/profile?schema', tasksData)
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Resend confirm code
   **/
  resendCode: async function (email) {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.post('/register/resend', { email: email })
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Get info tasks
   **/
  infoTasks: async function () {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.get('/info/tasks')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Delete account
   **/
  deleteAccount: async function () {
    store.commit('loadingStatus', true)

    try {
      const response = await ApiService.delete('/profile')
      return response.data
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Save responses from login/checkin
   **/
  saveUserData (payload) {
    const { tokens, profile, schema, tasks } = payload.data
    if (tokens.id) StorageService.saveToken(tokens.id)
    if (tokens.expires) StorageService.saveTokenExpires(tokens.expires)
    if (tokens.refresh) StorageService.saveRefreshToken(tokens.refresh)
    if (tasks) StorageService.saveTasks(tasks)
    if (profile) StorageService.saveProfile({ profile, schema })

    ApiService.setHeader()
  },

  /**
   * Save facebook access token
   **/
  saveFbUserData (payload) {
    if (payload.accessToken) StorageService.saveToken(payload.accessToken)
    if (payload.expires) StorageService.saveTokenExpires(payload.expires)

    ApiService.setHeader()
  },

  /**
   * Submit oauth login form
   **/
  oauthLogin: async function (loginData, urlParams) {
    store.commit('loadingStatus', true)

    // get query params
    const clientId = urlParams.get('client_id')
    const uri = urlParams.get('redirect_uri')
    const state = urlParams.get('state')

    // append client_id
    loginData.client_id = clientId

    try {
      const response = await ApiService.post('/oauth/login', loginData)
      const code = response.data.authorization_code
      return { uri, state, code }
    } catch (error) {
      throw new AuthenticationError(error.response.status, error.response.data.message)
    } finally {
      store.commit('loadingStatus', false)
    }
  },

  /**
   * Logout
   **/
  logout () {
    // remove the token and the profile
    StorageService.removeToken()
    StorageService.removeRefreshToken()
    StorageService.removeProfile()
    StorageService.removeTokenExpires()

    // remove the tasks
    StorageService.removeTasks()

    // remove the authorization header from API service
    ApiService.removeHeader()
  }
}

export default UserService

export {
  UserService,
  AuthenticationError
}
